import './css/Slideshow.css';

import React, 
  {useState, 
  useEffect} from 'react';


export default function Slideshow({urls,interval,music}){
  const [currentIndex, setCurrentIndex] = useState(0);
  function next(){
    //console.log(`now ${currentIndex} len ${urls.length}`);
    setCurrentIndex(currentIndex+1 >= urls.length ? 0: currentIndex + 1);
  }
  function prev(){
    //console.log(`now ${currentIndex} len ${urls.length}`);
    setCurrentIndex(currentIndex-1 < 0 ? urls.length - 1: currentIndex - 1);
  }

  return(
    <div className='slideshow-frame'>
      <div className='slideshow-holder'>
        {urls.map((url,index) => (
          <div 
            key = {index}
            style= {{
              display : currentIndex === index ? "block" : "none",
              width: "100%",
              height: "100%",
              position: "absolute",
            }}
          >
            <iframe 
                src={url}
                className='slideshow-iframe'
                title={`Slide ${index + 1}`}
            >
            </iframe>
          </div>
        ))}
      </div>
      <div className='slideshow-controls'>
        <div className='slideshow-button hyozan-button primary' onClick={prev}>
          <p>prev</p>
        </div>

        <div className='slideshow-button hyozan-button primary' onClick={next}>
          <p>next</p>
        </div>
      </div>
    </div>
  );
}
