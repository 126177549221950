import './css/SiteManager.css'

// import {Button, Grid, Label, View } from '@aws-amplify/ui-react';
import { useEffect, useState } from 'react';

import  DBCache  from './DBCache'; 
import  CommonValidations  from './Validations'
import HyozanForm from './HyozanForm';
import { useAppContext } from './Context';
import  Waiter from './Waiter';

export default function SiteManager(){
  
  const [showAddSite, setShowAddSite] = useState(false);
  const [jobArn, setJobArn] = useState(null);         // This is used to show progress when site is being created
  const [siteList, setSiteList] = useState(null);
  const {loginToken} = useAppContext();

  const db = new DBCache();
  const cv = new CommonValidations();

  useEffect(dbFetchSiteList,[]);

  function dbFetchSiteList() {
    db.getSiteList( setSiteList);
  }

  function AddSiteForm(){
    const [formData, setFormData] = useState({sitename:'', description:''});
    const [waiting, setWaiting] = useState(false);    // waiting for a response - show a gif

    const handleChange = (e) => {
      const {name, value } = e.target;
      setFormData({...formData, [name]:value});
    };

    const siteCreationResultHandler = (res) => {
      console.log("Got result", res);
      setWaiting(false);
      setShowAddSite(false);
      if (res.result == 'success') {
        setJobArn(res.Arn);
      }
    };

    function handleSubmit(vals){
      console.log(vals);
      setWaiting(true);
      if (!('sitename' in vals && 'description' in vals)){
        console.log("Internal Error - expected values not found in submit");
        console.log(vals);
        return;
      }

      if (cv.validateSiteName(vals.sitename) == false) {
        window.alert("Invalid site name");
        return;
      };

      if (cv.validateDescription(vals.description) == false)
      {
        window.alert("Invalid description");
        return;
      }
      const response = db.createNewSite({
          siteName: vals.sitename,
          siteDescription: vals.description,
          gitURL: vals.gitURL,
          numStaging: vals.numStaging,
        },
        siteCreationResultHandler       // This will be called back with a structure informing what happened to the site creation
        );
      setFormData({sitename:'', description:''}); // reset values after submission
      
    }

    const handleCancel = (e) => {
      setFormData({sitename:'', description:''}); // reset values after submission
      setShowAddSite(false);
    }

    const vals={
      FormName: 'Add a new site',
      Fields :[
        {
          Label : 'Site fully qualified domain name (FQDN)',
          Explanation: 'Enter the full domain name of the site you wish to create \
                (eg. marketing.staging.hyozan.com). Please ensure the base domain \
                name is already registered and available in the cloud environment',
          InputType : 'INPUT',
          onChange: handleChange,
          Prompt: {
            Type: 'INFO',
            Label: 'Please input the site name...'
          },
          Value : formData.sitename,
          ID : 'sitename',
          InitValue : 'xyz.example.com'
        },
        {
          Label : 'Site Description',
          Explanation: 'Enter a description to identify the purpose of this site',
          InputType : 'TEXTAREA',
          onChange: handleChange,
          Prompt: {
            Type: 'INFO',
            Label: 'Please input the site description...'
          },
          Value : formData.description,
          ID : 'description',
          InitValue : 'A brief description of this new site'
        },
        {
          Label: 'Code repository',
          Explanation: 'Choose the source code git repository where your site '+
                 'code is located. For example, this could be a github URL '+
                 'like https://github.com/JapanSriram/hajime_classic.git . '+
                 'This site will be synced with the "public" folder of '+
                 'entered git repository. It is important to ensure that '+
                 'appropriate access tokens are configured at the backend '+
                 'for granting access to this repository.',
          InputType: 'LIST',
          Options: [
            { 
              ID:'https://www.github.com/JapanSriram/hajime_classic.git',
              Text:'classic',
            },
            {
              ID:'https://www.github.com/JapanSriram/hajime_modern.git',
              Text:'modern',
            }
            ],
          onChange: handleChange,
          Prompt: {
            Type: 'INFO',
            Label: 'Please input git URL...'
          },
          Value: formData.gitURL,
          ID: 'gitURL',
          InitValue: 'https://github.com/JapanSriram/hajime_modern.git'
        },
        {
          Label: 'Number of staging sites',
          Explanation: 'Staging sites are used for holding interim jobs '+
                 'and updates to the main site. If you anticipate '+
                 'multiple parallel change jobs to the site, please '+
                 'increase the number of staging sites. If your main '+
                 'site is classic.hyozan.com , we will automatically '+
                 'create staging0.classic.hyozan.com , staging1.classic'+
                 '.hyozan.com etc, and preload all of them with default '+
                 'hello page.',
          InputType: 'LIST',
          Options: [
            {
              ID: '1',
              Text: '1',
            },
            {
              ID: '2',
              Text: '2',
            },
            {
              ID: '3',
              Text: '3',
            },
            {
              ID: '4',
              Text: '4',
            },
            {
              ID: '5',
              Text: '5',
            },
            ],
          onChange: handleChange,
          Prompt: {},
          Value: formData.numStaging,
          ID: 'numStaging',
          InitValue: '1',
          },
      ],
      onSubmit : handleSubmit,
      onCancel : handleCancel,
      SubmitText: "Create Site",
    };

    return (
      <>
        <HyozanForm FormVals={vals} />
        {waiting && <Waiter />}
      </>
    )
     
  }

  const setShowAddSiteTrue = () => {console.log(Date.now());setShowAddSite(true)};
  const refreshSiteList = () => {db.getSiteList(setSiteList, true)}; // Force a refresh

  var i=0;

  return(
    <div className='hyozan-workspace'>
      <div className='hyozan-dashboard'>
        <div className='dashboard-header'> 
          <div className="dashboard-title">
            <p>List of sites</p> 
          </div>
          
          <div className='dashboard-spacer'></div>

          <div className='dashboard-controls'>
            <div className="refresh">
              <div title="refresh" onClick={refreshSiteList}>
                <img src='refresh.png' alt="refresh"/>
              </div>
            </div>
            <div className="control-button">
              <button title="create  site" className='hyozan-button active' onClick={setShowAddSiteTrue}> Add Site</button>
            </div>
          </div>
        </div>      
        <table className='hyozan-table'>
          <SiteListHeader />
          <tbody>
            {siteList &&
              siteList.map((site) => {return(
                  <SiteListRow siteVals={site} key={i++} />
              )}) 
            }
          </tbody>
        </table>      
      </div>
      <div>
        {showAddSite &&
          <div>
            <AddSiteForm />
          </div>
        }
      </div>
      <div>
        {(jobArn ) && 
          <div>
            <ShowSiteBuildStatus siteName={""} functionArn={jobArn} setArn={setJobArn}/>
          </div>
        }
      </div>
    </div>
  )
}

function ShowSiteBuildStatus ({siteName, functionArn, setArn}) {
  const [jobStatus, setJobStatus] = useState(""); // A list of statuses returned from the job
  const [progress, setProgress] = useState({
                        'distribution': false,
                        'certificate': false,
                        'dns': false,
                        'logbucket': false,
                        'sitebucket': false
                      });
  const [steps, setSteps] = useState(0);
  let   intervalID;
  const db = new DBCache();

  // const intervalID = setInterval(checkStatus,5000);
  function checkStatus(){
    const str = db.getJobStatus(functionArn, callbackForStatus);
  }

  function callbackForStatus(vals) {
    if (vals) {
      console.log(vals);
      const jobstatus = vals.executionStatus.status;
      setJobStatus(jobstatus);
      
      const progressCopy = {...progress};

      // Now look at the history to figure out the status
      if (("history" in vals) && ("events" in vals.history)) {
        setSteps(vals.history.events.length);
        for (var i=0; i < vals.history.events.length; i++) {
          switch (vals.history.events[i].type ){
            case 'TaskStateExited': {
              switch (vals.history.events[i].stateExitedEventDetails.name) {
                case 'Create Main Bucket': progressCopy.sitebucket = true; break;
                case 'Create Log Bucket' : progressCopy.logbucket = true; break;
                case 'Check certificate issued': progressCopy.certificate = true;break;
                default:break;

              }
              break;
            }
            default:
              break;
          }
        }
      }
      if (jobstatus != 'RUNNING') {
        clearInterval(intervalID);
      }
      if (jobstatus == 'SUCCEEDED') {
        setProgress({
          'distribution': true,
          'certificate': true,
          'dns': true,
          'logbucket': true,
          'sitebucket': true
        });
      }
    }
    else
    {
      clearInterval(intervalID);
      setJobStatus("ERROR!");
    }
  }
  

  useEffect(() => {
    intervalID = setInterval(checkStatus, 9000);
    return () => clearInterval(intervalID);
  },[])

  return (
    <div className="ProgressViewBox">
      <div className="ProgressHeader">
        <div className="ProgressHeading">
          <span className="ProgressSiteName">
            Building site in progress...
          </span>
        </div>
      </div>
      <div className="ProgressBody">
        <div className="ProgressIconsHolder">
          <div className="ProgressIconsGrid">
            <img 
              src="distr-icon.png"  
              className={progress.distribution?'ProgressImg colored-img' : 'ProgressImg grayscale-img'}
              id="progress-distribution-icon" />

            <img 
              src="cert-icon.png"   
              className={progress.certificate?'ProgressImg colored-img' : 'ProgressImg grayscale-img'}
              id="progress-cert-icon"/>

            <img 
              src="dns-icon.png"    
              className={progress.dns?'ProgressImg colored-img' : 'ProgressImg grayscale-img'}
              id="progress-dns-icon"/>

            <img 
              src="log-icon.png"    
              className={progress.logbucket?'ProgressImg colored-img' : 'ProgressImg grayscale-img'}
              id="progress-log-icon"/>

            <img 
              src="bucket-icon.png"   
              className={progress.sitebucket?'ProgressImg colored-img' : 'ProgressImg grayscale-img'}
              id="progress-bucket-icon"/>
          </div>
        </div>
        <div className="ProgressInfo">
          <div className="ProgressStepsGrid">
            <div className="ProgressStepCount">
              <span>{steps}</span>
            </div>
            <div className="ProgressStepLegend">Steps</div>
          </div>
          <div className="ProgressStatusHolder">
            <div className="ProgressStatus">
              <span>{jobStatus}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="ProgressFooter">
        <div className="ProgressFooterButtonHolder">
          <button 
            onClick={()=>{clearInterval(intervalID); setArn(null)}} 
            className='hyozan-form-cancel-button hyozan-button cancel'>
              Close
          </button>         
        </div>
      </div>
    </div>

  );
}

function SiteListHeader(){
  return (
    <thead className='hyozan-table-header'>
      <tr className='hyozan-table-header-tr'>
        <th> Name </th>
        <th> Description </th>
        <th> Status </th>
        <th> bucket </th>
        <th> Jobs </th> 
        <th> Traffic </th>
      </tr>
    </thead>
  );
}

function SiteListRow({siteVals}){   
  const site = siteVals;
  const url = "https://" + site.siteName + "/index.html";
  var   tdClass;
  switch (site.siteStatus) {
    case 'ACTIVE': tdClass = 'site-active'; break;
    case 'INITIAL' : tdClass = 'site-initial'; break;
    default: tdClass = 'site-unknown'; break;
  }
  return (
    <tr key={site.siteName} className='hyozan-table-row'>
      {
        site.siteStatus === 'ACTIVE' ?
          <td><a href={url} target="_blank">{site.siteName}</a></td>
      :
          <td className={tdClass}>{site.siteName}</td>
      }
      <td>{site.siteDescription ? site.siteDescription.slice(0,30) : ""}</td>
      <td>{site.siteStatus?? site.siteStatus}</td>
      <td>{site.siteBucket? site.siteBucket : '?'}</td>
      <td> - </td>
      <td> - </td>
    </tr>
  )
}
