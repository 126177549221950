import './css/Sorter.css';

import React, {useState} from 'react';


export default function Sorter({field,data,setter}){

 
  if(field === undefined){
    console.log('field is undefined');
    return;
  }

  const fields=field.split('.');

  function sortDataAscending(){
    sortData(true);
  }

  function sortDataDescending(){
    sortData(false);
  }

  // sort function with boolean input. true means ascending
  // sort of the data array passed on the field provided
  function sortData(ascending){
    
    function comparator(a,b){
      try{
      let lhs=a;
      let rhs=b;

      for(let i=0; i < fields.length; i++){
        lhs = lhs[fields[i]];
        rhs = rhs[fields[i]];
      }

      if(ascending){
        return((lhs > rhs) ? 1 : -1)
      }
      else{
        return((lhs < rhs) ? 1 : -1)
      }
      }catch(err){
        console.log(err);
        console.log(a);
        console.log(b);
        console.log(fields);
        return 0;
      }
    }

    try{
      const sorted = data.toSorted(comparator);
      setter(sorted);
    } catch(err) {
      console.log(err);
    }

  }

  return(
    <div className='sorter-box'>
      <img 
        src='ascending.png' 
        onClick={sortDataAscending} 
        alt='ascending sort'/>
      <img 
        src='descending.png' 
        onClick={sortDataDescending} 
        alt='descending sort'/>
    </div>
  );
}
