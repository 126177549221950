
import React, {useState} from 'react';
import Sorter from './Sorter.js';

export default function TableHeader({fields,data,setter}){
  return (
    <thead className='hyozan-table-header'>
      <tr className='hyozan-table-header-tr'>
        {fields.map((f,n) => {
          return(
            <th key={n}>
              {f.sort && 
                <div className='hyozan-table-header-sorter'>
                  <span className='hyozan-table-sorter-label'>{f.label}</span>
                  <Sorter field={f.fieldname} data={data} setter={setter}/>
                </div>
              }
              {!f.sort && <span>{f.label}</span>}
            </th>
            )})}
      </tr>
    </thead>
    );
}

