import './css/CallWindow.css'

import { useState, useRef } from 'react';
import Video from 'twilio-video';
import Waiter from './Waiter';

import DBCache from './DBCache';

export default function CallWindow({closeHandler}){
  const [waiting, setWaiting] = useState(false);
  const [room, setRoom] = useState(null);
  const [remoteParticipant, setRemoteParticipant] = useState(null);
  const localVideoRef = useRef(null);
  const remoteVideoRef = useRef(null);


  const db = new DBCache();

  function startVideo(){

    async function connectHandler(videoToken){
      console.log('about to start video');
      console.log(videoToken);

      if(videoToken === null)
        window.alert('Unable to fetch token. Please retry');

      if(videoToken){
        try {
          const videoRoom = await Video.connect(videoToken,
            {
              name: 'hyozan-test',
              audio: true,
              video: true,
            });
          console.log(videoRoom);
          setRoom(videoRoom);

          const localTrack = Array.from(videoRoom.localParticipant.videoTracks.values())[0]?.track;
          if (localVideoRef.current && localTrack) {
            localTrack.attach(localVideoRef.current);
          }

          console.log('now trying to get the remote participant if alredy there...');


          console.log(`participant values are ${Object.values(videoRoom.participants)}`);

          videoRoom.on("participantConnected", (participant) => {
            console.log('remote participant connected: ', participant);
            setRemoteParticipant(participant);

            participant.on("trackSubscribed", (track) => {
              console.log(track);
              if(remoteVideoRef.current ) {
                console.log('attaching ');
                track.attach(remoteVideoRef.current);
              }
            });
          });

          setWaiting(false);

        } catch(err){
          console.log('got error when connecting video');
          console.log(err);
        }
      }
    };

    setWaiting(true);
    db.initVideo('consolewala',connectHandler); // Hardcoded name for now
  }

  function handleClose(){
    if(room){
      room.disconnect();
      setRoom(null);
    }
    closeHandler();
  }

  function refreshRemote(){
    console.log('refresh remote clicked');
    console.log(room);
    if(!room){
      console.log('no room - just returning');
      return;
    }

    // Only look for remote participants
    room.participants.forEach(participant => {
      console.log('participant found');
      console.log(participant);
      participant.tracks.forEach(publication => {
        console.log('publication found');
        console.log(publication);
        const track = publication.track;
        console.log('track is');
        console.log(track);
        if(track){
          console.log('attaching track');
          try{
            track.attach(remoteVideoRef.current);
          }catch(err){
            console.error(err);
            console.error('attach failed');
          }
        }
      });
    });
  }


  return(
      <div className='callwindow-frame'>
        <div className='form-heading'>
          <div className='form-header-holder'>
            <label className='hyozan-form-title'>Video / Audio Call </label>
          </div>
        </div>
        <div className='callwindow-video-holder'>
          <div className='callwindow-video' id='callwindow-my-video'>
            <video ref={localVideoRef} autoPlay muted className='callwindow-video' />
          </div>
          <div className='callwindow-video' id='callwindow-other-video'>
            <video ref={remoteVideoRef} autoPlay  className='callwindow-video' />
          </div>
        </div>
        <div className='callwindow-video-controls hyozan-form-button-area'>
          <button 
            className='hyozan-button cancel' 
            onClick={handleClose}
          >
            close
          </button>

          <button
            className='hyozan-button primary'
            onClick={startVideo}
          >
            start
          </button>
          
          <button
            className='hyozan-button secondary'
            onClick={refreshRemote}
          >
            refresh
          </button>
        </div>

        {waiting && <Waiter />}

      </div>
  );
}




