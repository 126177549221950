
import './css/Waiter.css';

export default function Waiter(){
  return(
    <div className='waiting'>
      <img className='waiting-img' src='waiting.gif' />
    </div>
  );
}
