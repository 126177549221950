// ConsoleView.js

import './css/ConsoleView.css'
import React from 'react';
import { useState } from 'react';
import { useAppContext } from './Context';

const ConsoleView = () => {
  const { statusMessages } = useAppContext();
  const [displayMode, setDisplayMode] = useState('closed'); // open or minimised currently

  function formatDate(dtVal) {
      const now = new Date(dtVal);
      const year = now.getFullYear().toString().slice(-2).padStart(2, '0');
      const month = (now.getMonth() + 1).toString().padStart(2, '0');
      const day = now.getDate().toString().padStart(2, '0');
      const hours = now.getHours().toString().padStart(2, '0');
      const minutes = now.getMinutes().toString().padStart(2, '0');
      const seconds = now.getSeconds().toString().padStart(2, '0');
      const milliseconds = now.getMilliseconds().toString().padStart(3, '0');

      return `${year}${month}${day}-${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

  function MessageFormat({msg}) {
    const messageStr = `${formatDate(msg.messageTime)} : ${msg.messageText}`;
    switch (msg.messageType) {
      case 'warn':
        return(<pre className='status-message message-warn'>{messageStr}</pre>);
      case 'success':
        return(<pre className='status-message message-success'>{messageStr}</pre>);
      case 'error':
        return(<pre className='status-message message-error'>{messageStr}</pre>);
      case 'info':
      default:
        return(<pre className='status-message message-info'>{messageStr}</pre>);
    }
  }

  function toggle(){
    if (displayMode === 'open')
      setDisplayMode('closed');
    else
      setDisplayMode('open');
  }

  if (displayMode === 'open'){
    return (
      <div className="console hyozan-form">
        <div className='console-header'>
          <div className='console-title'>
            Hajime console status log
          </div>
          <div className='console-toggle'>
             <p onClick={toggle}> minimize </p>
          </div>
        </div>
        <ul className="console-list">
          {statusMessages.map((message, index) => (
            <li className="console-list-line" key={index}>
                <MessageFormat msg={message} />
            </li>
          ))}
        </ul>
      </div>
    );
  } else { // Show the last line of the statusMessages
    const numMsg = statusMessages.length;
    if (numMsg > 0) {
      return (
        <div className='console-single-line'>
          <div className='console-list-line'> 
            <MessageFormat msg={statusMessages[numMsg - 1]} />
          </div>
          <button className='console-expand-button' onClick={toggle}> expand </button>
        </div>
      );
    }
  } 

};

export default ConsoleView;
