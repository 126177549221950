import './css/LeadAnalytics.css'

import { useEffect, useState } from 'react';

import HyozanForm from './HyozanForm';
import DBCache from './DBCache';
import CommonValidations from './Validations';

const leadStatusVals = [
    'INITIAL',
    'ASSIGNED',
    'CONTACTED',
    'WARM',
    'CONVERTED',
    'LOST',
    'COLD',
    'NOTCONTACTABLE',
];

export default function LeadsFlow(){

    const [leadFlowData, setLeadFlowData] = useState([]);
    const [waiting, setWaiting]           = useState(false);

    const db = new DBCache();
    const cv = new CommonValidations();

    const formData = {
        startDate: '01/01/2023',
        endDate: '10/10/2023',
        formName: 'hyozan-form-hero'
    };

    const vals={
        FormName: 'Choose Leads Filters',
        Fields:[
            {
                Label: 'Start Date',
                Explanation: 'Enter the start date for lead data (dd/mm/yyyy)',
                InputType: 'INPUT',
                onChange: handleChange,
                Prompt:{
                    Type: 'INFO',
                    Label: 'Please enter start date for lead data (dd/mm/yyyy)'
                },
                Value: FormData.startDate,
                ID: 'startDate',
                InitValue : '01/10/2023'        // TODO - Compute this to beginning of this month
            },
            {
                Label: 'End Date',
                Explanation: 'Enter the end date for lead data',
                InputType: 'INPUT',
                onChange: handleChange,
                Prompt: {
                    Type: 'INFO',
                    Label: 'Please enter end date for lead data...'
                },
                Value: FormData.endDate,
                ID: 'endDate',
                InitValue: '01/11/2023'         // TODO - Compute this to be yesterday
            },
            {
                Label: 'Forms',
                Explanation: 'Choose which forms you want lead info',
                InputType: 'LIST',
                Options: [
                    {
                        ID: 'hyozan-form-hero',
                        Text: 'home page hero form'
                    },
                    {
                        ID: 'hyozan-form-cards',
                        Text: 'cards lead form'
                    }
                ],
                onChange: handleChange,
                Prompt: {
                    Type: 'INFO',
                    Label: 'Choose forms'
                },
                ID: 'formName',
                InitValue: null
            }
        ],
        onSubmit: handleSubmit,
        onCancel: handleCancel,
        SubmitText: 'View Lead Analytics'
    };

    function handleChange(e){}

    function handleSubmit(vals){
        console.log(vals);

        const lStartDate = cv.ddmmyyyy2Date(vals.startDate);
        const lEndDate   = cv.ddmmyyyy2Date(vals.endDate);
        
        if ((lStartDate  != null) &&  (lEndDate != null)) {
            const fieldvals = {
                startDate: lStartDate,
                endDate  : lEndDate,
                formName : vals.formName
            }
            setWaiting(true);
            db.getLeadFlowData(fieldvals, setLeadFlowData);

        }  
    }

    function handleCancel(){
        setLeadFlowData([]);
    }

    return(
        <div className='hyozan-workspace'>
            <HyozanForm FormVals={vals} />
            <LeadFlowView leadData={leadFlowData}/>
        </div>
    )

    function LeadFlowView({leadData}){
        if (leadData.length <= 0)
            return;

        return(
            <div className='hyozan-dashboard'>
                <div className='dashboard-header'>
                    <div className='dashboard-title'>
                        <p> Lead Flow Analytics </p>
                    </div>
                </div>

                <div className='dashboard-spacer'></div>
                <table className='hyozan-table'>
                    <LeadFlowViewHeader /> 
                    <tbody>
                        {leadData.map((lead) => {return(
                             <LeadFlowRow rowVal={lead} />
                        )})
                        }
                        <LeadFlowSummary leadData={leadData}/>
                    </tbody>
                </table>
            </div>
        )
    }

    function LeadFlowSummary({leadData}){
        const totals={};
        let grandTotal=0;
        leadStatusVals.map((l)=>{totals[l]=0});
        leadData.map((row)=>{
            row.statusCounts.map((c)=>{totals[c.status]+=c.count; grandTotal += c.count});
        });
        
        
        return (
            <tr key='12345' >
                <td>Total  </td>
                <td>{totals.INITIAL}        </td>
                <td>{totals.ASSIGNED}       </td>
                <td>{totals.CONTACTED}      </td>
                <td>{totals.WARM}           </td>
                <td>{totals.CONVERTED}      </td>
                <td>{totals.LOST}           </td>
                <td>{totals.COLD}           </td>
                <td>{totals.NOTCONTACTABLE} </td>
                <td>{grandTotal}            </td>
            </tr>
        )
    }

    function LeadFlowViewHeader(){
        return (
            <thead className='hyozan-table-header'>
                <tr className='hyozan-table-header-tr'>
                    <th> Date           </th>
                    <th> Inital         </th>
                    <th> Assigned       </th>
                    <th> Contacted      </th>
                    <th> Warm           </th>
                    <th> Converted      </th>                    
                    <th> Lost           </th>                    
                    <th> Cold           </th>                    
                    <th> Not<br/>contactable</th>                    
                    <th> total          </th>                    
                </tr>
            </thead>
        );
    }

    function LeadFlowRow({rowVal}){
        function getCount(key, vals){
            for(let i=0; i < vals.length;i++)
                if(vals[i].status === key)
                    return vals[i].count;
            
            return 0;
        }
        
        return (
            <tr key={rowVal.date} >
                <td>{rowVal.date}  </td>
                <td>{getCount('INITIAL',    rowVal.statusCounts)}</td>
                <td>{getCount('ASSIGNED',   rowVal.statusCounts)}</td>
                <td>{getCount('CONTACTED',  rowVal.statusCounts)}</td>
                <td>{getCount('WARM',       rowVal.statusCounts)}</td>
                <td>{getCount('CONVERTED',  rowVal.statusCounts)}</td>
                <td>{getCount('LOST',       rowVal.statusCounts)}</td>
                <td>{getCount('COLD',       rowVal.statusCounts)}</td>
                <td>{getCount('NOTCONTACTABLE',rowVal.statusCounts)}</td>
                <td>{rowVal.totalLeads}</td>
            </tr>
        )
    }
}

// //{"statusCounts":
//     [{"status":"WARM","count":7},{"status":"CONVERTED","count":4},{"status":"ASSIGNED","count":4},{"status":"COLD","count":3},{"status":"CONTACTED","count":4},{"status":"NOTCONTACTABLE","count":2},{"status":"INITIAL","count":3},{"status":"CLOSED","count":3},{"status":"LOST","count":1},{"status":"QUALIFIED","count":4}],
//     "totalLeads":35,
//     "date":"2024-01-01"}