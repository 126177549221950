/***
 * 
 * This is a utility react component that will handle any form inputs in hyozan.
 * 
 * The look and feel of this is modelled on the AWS console dark mode (for day 1)
 * 
 * The inputs to the component are:
 * 
 * 1. FormName - The name to be displayed as the title of the form
 * 2. Fields - An array of field objects
 * 2.1      Label  - The primary field label
 * 2.2      Explanation - The explanatory text for the field. If this is null or blank, it is omitted
 * 2.3      InputType - can be 
 *                  - "INPUT" : Basic text input
 *                  - "TEXTAREA" : Multiline input
 *                  << TODO: More Later  >>
 * 2.4      onChange - A function that will be invoked every time this field changes
 * 2.5      Prompt   - The initial value displayed
 * 2.6      Value  - The updated value
 * 2.7      ID     - The ID of the input element for this field. can be used by handlers
 * 
 * 3. onSubmit - Function called when submit clicked
 * 4. onCancel - Function called when cancel clicked
 * 
 * 
 * 
 */


import './css/HyozanForm.css'
import {  useState, useRef } from 'react';

export default function HyozanForm({FormVals}){

  // <<TODO: Add error checking on the parameters so that there are no runtime errors
  // add try/ catch logic as well.

  // console.log(FormVals); // <<TODO : For debug only >>

  const [waiting, setWaiting] = useState(false);
  const formData = useRef({}); // This will contain the form data

  function ListRowWithCheck({rowVal, onClick, rowID}){
    return (
      <div className="hyozan-form-list-row-with-check" key={rowID}>
        <div onClick={onClick} className='hyozan-form-row-check-holder' id={'form-row:' + rowID}>
          <svg id={'form-row-svg:'+ rowID} xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path id={'form-row-path:' + rowID} d="M280-120q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520ZM360-280h80v-360h-80v360Zm160 0h80v-360h-80v360ZM280-720v520-520Z"/>
          </svg>
        </div>
        <div className='hyozan-form-row-text-holder'>
          <span className='hyozan-form-row-text'> {rowVal} </span>
        </div>
      </div>
    )
  }
  function HyozanFormFieldPrompt({Prompt}) {
    var iconSrc="";
    var promptClass='hyozan-form-prompt-label';

    if (Prompt == null)
      return; // Nothing to show

    switch(Prompt.Type){
      case 'INFO':
        iconSrc='img/icon-prompt-info.png';
        promptClass += '-info';
        break;
      case 'WARNING':
        iconSrc='img/icon-prompt-warn.png';
        promptClass += '-warn';
        break;
      case 'ERROR':
        iconSrc='img/icon-prompt-error.png';
        promptClass += '-error';
        break;
      default: // Something wrong
        console.log("Invalid prompt type");
        return;
    }
    return(
      <div className='hyozan-form-prompt-holder'>
        <img className='hyozan-form-prompt-icon' alt='prompt' src={iconSrc} />        
        <label className={promptClass}>{Prompt.Label}</label>
      </div>
    )
  }

  function HyozanFormField({Field, formData}) {
    const [fieldData, setFieldData] = useState('');

    const handleChange = (e) => {
      const { value } = e.target;
      setFieldData(value);
      formData.current[e.target.id] = value;
      // console.log(formData);
    };

    const delRow = (e) => {
      const attachToRemove = e.target.id.split(':')[1];
      const newAttachmentList=[];
      for (let i=0; i < fieldData.length;i++)
        if (i !== attachToRemove)
          newAttachmentList.push(fieldData[i]);
      setFieldData(newAttachmentList);
      formData.current[Field.ID] = newAttachmentList;
      // console.log(formData);

    }

    const addRow = (e) => {
      // console.log("Add row @", Date.now());
      e.preventDefault();
      const newAttachmentList=[];
      if (fieldData && (typeof(fieldData) == 'object'))
        for (let j=0; j < fieldData.length; j++)
          newAttachmentList.push(fieldData[j]);
      for (let i=0; i< e.target.files.length;i++)
        newAttachmentList.push(e.target.files[i])
      setFieldData(newAttachmentList);
      formData.current[Field.ID] = newAttachmentList;
      // console.log(formData);

    }

    var key=0;    // To fix browser warnings from react


    switch(Field.InputType) {
      case 'STATICTEXT':
        return(
          <div className='hyozan-form-input-holder'>
            <input
              type="text"
              className='hyozan-form-input'
              id={Field.ID}
              name={Field.ID}
              value={Field.Value}
              disabled={true}
              required
              key={key++}
            />
          </div>
        );
      case 'INPUT':
        return(
        <div className='hyozan-form-input-holder'>
          <input
            type="text"
            className='hyozan-form-input'
            id={Field.ID}
            name={Field.ID}
            value={fieldData}
            placeholder={Field?.InitValue}
            onChange={handleChange}
            disabled={waiting}
            required
            key={key++}
          />
        </div>
        );
      case 'PASSWORD':
        return(
        <div className='hyozan-form-input-holder'>
          <input
            type="password"
            className='hyozan-form-input'
            id={Field.ID}
            name={Field.ID}
            value={fieldData}
            placeholder={Field?.InitValue}
            onChange={handleChange}
            disabled={waiting}
            required
            key={key++}
          />
        </div>
        );
      case 'TEXTAREA':
        return(
        <div className='hyozan-form-input-holder'>
          <textarea
            type="text"
            className='hyozan-form-input'
            id={Field.ID}
            name={Field.ID}
            value={fieldData}
            placeholder={Field?.InitValue}
            onChange={handleChange}
            disabled={waiting}
            required
            key={key++}
          />
        </div>
        );
      case 'LIST':
        return(
        <div className='hyozan-form-input-holder' key={key++}>
          <select
            type="text"
            label={Field.ID}
            className='hyozan-form-input'
            id={Field.ID}
            name={Field.ID}
            value={fieldData}
            placeholder={Field?.InitValue}
            onChange={handleChange}
            disabled={waiting}
            required
          > 
            <option value="" disabled  hidden></option>
            {Field.Options.map((o) => {
              return(<option className='hyozan-form-list-option' key={key++} value={o.ID}>{o.Text}</option>)
            })}
          </select>
        </div>
        );
      case 'BUTTON':
        return(
          <div className='hyozan-form-button-holder' key={key++}>
            <label htmlFor={Field.ID} 
              className='hyozan-form-upload-button hyozan-button'>
                {Field.Text}
            </label>
            <input 
              type='file' 
              name={Field.ID} 
              multiple 
              className="hyozan-form-file-input-hidden" 
              onChange={Field.Handler}
              key={key++}
              id={Field.ID}/>
          </div>
        );
      case 'LIST-WITH-CHECKS':
        return(
          <div className='hyozan-form-list-with-checks' key={key++} id={Field.ID}>
            {
              Field.List.map((row,num) => {
                return(<ListRowWithCheck 
                  rowVal={row} 
                  rowID={num} 
                  onClick={Field.Handler} 
                  key={key++} />);
              })
            }
          </div>
        );
      case 'SUBFORM-ARRAY':
        // One or more instances of a component will be shown below
        return(
          <div className='hyozan-form-subform-holder' key={key++} id={Field.ID}>
            {Field.SubFormComponent()}
          </div>
        );
      case 'FILELIST-WITH-BUTTON':
        // A list of files with checkmarks to remove them, 
        // and also a button to add new files
        return(
          <div className='hyozan-filelist-with-button'>
            <div className='hyozan-form-list-with-checks' id={Field.ID}>
              {
                (fieldData.length > 0) &&
                fieldData.map((row,num) => {
                  return(<ListRowWithCheck 
                    rowVal={row.name}
                    rowID={num}
                    onClick={delRow}
                    key={key++}
                  />);
                })
              }
            </div>
            <div className='hyozan-form-button-holder' key={key++}>
              <label htmlFor={Field.ID} 
                className='hyozan-form-upload-button hyozan-button'>
                  {Field.Text}
              </label>
              <input 
                type='file' 
                name={Field.ID} 
                multiple 
                className="hyozan-form-file-input-hidden" 
                onChange={addRow}
                key={key++}
                id={Field.ID}/>
            </div>
          </div>
        )
      default:
        return;
    }
  
  }

  function doSubmit(e){

    e.preventDefault();
    try{
      FormVals.onSubmit(formData.current);
    }catch(err) {
      console.log(err);
    }
  }

  return (
    <form onSubmit={doSubmit} className='hyozan-form'>
      {waiting && <img src="waiting.gif" className="waiting-gif" alt="waiting for response" />}

      <div className='form-heading'>
        <div className='form-header-holder'>
          <label className='hyozan-form-title'> {FormVals.FormName} </label>
        </div>
      </div>

      <div className='form-fields-area'>
        {FormVals.Fields.map((field) => {
          return(
            <div key ={field.ID} className='hyozan-form-field'>
              <div className='hyozan-form-field-label'>
                <label>{field.Label}</label>
              </div>
              <div className='hyozan-form-field-explanation'>
                <label>{field.Explanation}</label>
              </div>
              <div className='hyozan-form-field-input'>
                <HyozanFormField Field={field} formData={formData} />
              </div>
              <div className='hyozan-form-field-prompt'>
                {(field.Prompt != null) ?? <HyozanFormFieldPrompt Prompt={field.Prompt}/>}
              </div>
            </div>
          )
        })}
      </div>

      <div className='hyozan-form-button-area'>
        <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-submit-button hyozan-button active' 
              disabled={waiting} 
              onClick={doSubmit}>
                {FormVals.SubmitText}
          </button>
        </div>
        <div className='hyozan-form-button-holder'>
          <button  className='hyozan-form-cancel-button hyozan-button cancel' 
              disabled={waiting} 
              onClick={FormVals.onCancel}>
                Cancel
          </button>
        </div>
      </div>
    </form>
  )
};

