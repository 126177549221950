
import './css/LeadSearch.css'

import { useEffect, useState } from 'react';

import  DBCache  from './DBCache'; 
import  CommonValidations  from './Validations'
import  HyozanForm from './HyozanForm';
import  TableHeader from './TableHeader';
import  CallWindow  from './CallWindow';
import  LeadManager from './LeadManager';
import  Waiter      from './Waiter';
import  LeadList    from './LeadList';

import { useAppContext } from './Context';

export default function LeadSearch(){
  const [waiting, setWaiting] = useState(false);
  const [formData, setFormData] = useState({leadName:'',phoneNumber:''});
  const [leadlist, setLeadlist] = useState(null);
  const db = new DBCache();
  const cv = new CommonValidations();

  function handleChange(e){
    const {name,value} = e.target;
    setFormData({...formData, [name]:value});
  }

  function handleSubmit(vals){

    console.log(vals);

    setWaiting(true);
    db.getLeadList(leadCallback, vals); //TODO : validations on values
    setFormData({leadName:'',phoneNumber:''});
  }

  function leadCallback(leadlist){
    setWaiting(false);
    console.log(leadlist);
    if(leadlist)
      setLeadlist(leadlist);
  }



  function handleCancel(){
  }

  const formVals = {
    FormName : 'Search for leads',
    Fields: [
      {
        Label: 'Name',
        Explanation: 'Enter the lead name (or any regex to match the name)',
        InputType: 'INPUT',
        onChange: handleChange,
        Prompt: {
          Type: 'INFO',
          Label: 'Please input lead name...',
        },
        Value: formData.leadName,
        ID: 'leadname',
        InitValue: 'Hiroshi Nakamura',
      },
      {
        Label: 'Phone',
        Explanation: 'Enter lead mobile phone number (or any regex to match it)',
        InputType: 'INPUT',
        onChange: handleChange,
        Prompt: {
          Type: 'INFO',
          Label: 'Please input phone number...',
        },
        Value: formData.phoneNumber,
        ID: 'phoneNumber',
        InitValue: '*2223*',
      },
    ],
    onSubmit: handleSubmit,
    onCancel: handleCancel,
    SubmitText: 'Find Leads',
  };

  return(
    <>
      <div className='hyozan-workspace'>
        <HyozanForm FormVals={formVals} />
        {waiting && <Waiter />}
      </div>
      {leadlist && <LeadList  key={Date.now()} initVals= {leadlist} />} 
    </>
  )
}
