import './css/PhoneCall.css'

import { useState, useRef } from 'react';
import {Device} from '@twilio/voice-sdk';
import Waiter from './Waiter';

import DBCache from './DBCache';

/*
  * Phone call states are:
  * Initial 
  * Connecting
  * Ringing
  * Talking
  * Disconnecting
  * Busy
  * Failed
  * 
*/


let phone = null; // global variable...

export default function PhoneCall({number,closeHandler, name}){
  const [waiting, setWaiting] = useState(false);
  const [callState, setCallState] = useState('Initial');
  const statusVals    = {
    Initial: {display:'Press call to start...',action:'call'},
    Connecting: {display:'connecting...',action:'.'},
    Ringing: {display:'Ringing...',action:'.'},
    Talking: {display:'Connected',action:'hangup'},
    Disconnecting: {display:'Disconnecting...',action:'.'},
    Busy: {display:'Busy..',action:'hangup'},
    Failed: {display:'Failed...',action:'call'},
  };
  const deviceOptions = {
    closeProtection: true,
    // Set Opus as our preferred codec. Opus generally performs better, requiring less bandwidth and
    // providing better audio quality in restrained network conditions. Opus will be default in 2.0.
    codecPreferences: ["opus", "pcmu"],
    // Use fake DTMF tones client-side. Real tones are still sent to the other end of the call,
    // but the client-side DTMF tones are fake. This prevents the local mic capturing the DTMF tone
    // a second time and sending the tone twice. This will be default in 2.0.
    fakeLocalDTMF: true,
    // Use `enableRingingState` to enable the device to emit the `ringing`
    // state. The TwiML backend also needs to have the attribute
    // `answerOnBridge` also set to true in the `Dial` verb. This option
    // changes the behavior of the SDK to consider a call `ringing` starting
    // from the connection to the TwiML backend to when the recipient of
    // the `Dial` verb answers.
    enableRingingState: true,
    debug: true,
  };
  const callParams = {
      To: '+919606021618',
  };
  const eventArray = [
    'destroyed',
    'error',
    'incoming',
    'registered'
  ];
  
  const db = new DBCache();
  let call = null;
  

  function handleClose(){
    if(phone){
      phone.destroy();
      phone = null;
    }
    closeHandler();
  }

  function eventListener(event){
    console.log(`got event ${event}`);
  }

  function startCall(){
    console.log('start call');
    setWaiting(true);
    db.getPhoneToken(tokenHandler);

    async function tokenHandler(token){
      console.log(`got token ${token}`);
      try{
        phone = new Device(token,deviceOptions);
        console.log('phone ok');
        console.log(phone);

        phone.on('error', (deviceError) => {
          console.error(deviceError);

          //window.alert(JSON.stringify(deviceError));
        });
        phone.on('ready',()=>{
          console.log('device ready');
          setCallState('Connecting');
        });
        phone.on('connect',()=>{
          console.log('phone connected');
          setCallState('Talking')
        });
        phone.on('disconnect',()=>{
          console.log('phone disconnected');
          setCallState('Initial');
        });

        console.log(callParams);
        console.log('now calling');
        call = await phone.connect(callParams);
        console.log('got call');
        console.log(call);
        if(call){
          call.on('ringing',()=>{
            console.log('ringing');
            setCallState('Ringing');
          });
          call.on('accept',()=>{
            console.log('accepted');
            setCallState('Talking');
          });

        }
        setWaiting(false);

      }catch(error){
        console.error(error);
        setWaiting(false);
      }
    }
  }

  function callAction(){
    switch(callState){
      case 'Initial':
        startCall();
        break;
      case 'Connecting':
      case 'Ringing':
      case 'Talking':
      case 'Disconnecting':
      case 'Busy':
      default: 
        if(phone){
          phone.destroy();
          phone = null;
        }
        setCallState('Initial');
        break;
    }
  }

  return(
    <div className='callwindow-frame'>

      <div className='form-heading'>
        <div className='form-header-holder'>
          <label className='hyozan-form-title'>Phone Call </label>
        </div>
      </div>

      <div className='form-area'>
        <div className='name-area'>
          <span className='name-prefix'>Calling</span>
          <span className='name-holder'>{name}</span>
        </div>

        <div className='status-area'>
          <span className='status-text'>
            {statusVals[callState].display}
          </span>
        </div>

        <div className='action-area'>
          <button
            className='hyozan-button primary'
            onClick={callAction}
          >
            {statusVals[callState].action}
          </button>
        </div>
      </div>

      <div className='callwindow-audio-controls hyozan-form-button-area'>
        <button 
          className='hyozan-button cancel' 
          onClick={handleClose}
        >
          close
        </button>

      </div>

      {waiting && <Waiter />}

    </div>
  );
}




