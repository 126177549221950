import ConsoleView from './ConsoleView';
import './css/Footer.css';

export default function Footer(){
    return(
        <div className='FooterBar'>
            <div className='console-envelope'>
                <ConsoleView />
            </div>
            <div className="Copyright">
                <p> © 2024 Hyozan Solutions Pte Ltd. All rights reserved </p>
            </div>
        </div>
    )
}